<template>
  <div>
    <b-container class="container-box">
      <b-row class="no-gutters">
        <b-col>
          <h1 class="font-weight-bold header-main text-uppercase mb-3">
            ติดต่อเรา
          </h1>
        </b-col>
      </b-row>

      <div class="bg-white p-3">
        <form>
          <b-row class="mt-1 mb-3">
            <b-col md="6">
              <div class="map">
                <GmapMap id="map" class="column" :center="center" :zoom="13">
                  <GmapMarker :position="center" :clickable="true" />
                </GmapMap>
              </div>
            </b-col>
            <b-col md="6">
              <InputText
                textFloat="ละติจูด"
                placeholder="ละติจูด"
                type="text"
                name="lat"
                isRequired
                :isValidate="$v.form.lat.$error"
                :v="$v.form.lat"
                v-model="form.lat"
              />
              <InputText
                textFloat="ลองจิจูด"
                placeholder="ลองจิจูด"
                type="text"
                name="lng"
                isRequired
                :isValidate="$v.form.long.$error"
                :v="$v.form.long"
                v-model="form.long"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <InputText
                textFloat="ชื่อที่อยู่"
                placeholder="ชื่อที่อยู่"
                name="contactUsAddress"
                isRequired
                :isValidate="$v.form.contactUsAddress.$error"
                :v="$v.form.contactUsAddress"
                v-model="form.contactUsAddress"
                type="text"
              />
            </b-col>
            <b-col md="6">
              <InputText
                textFloat="เบอร์โทร"
                placeholder="เบอร์โทร"
                name="contactUsTelephone"
                :isValidate="$v.form.contactUsTelephone.$error"
                :v="$v.form.contactUsTelephone"
                v-model="form.contactUsTelephone"
                type="text"
                isRequired
              />
            </b-col>
            <b-col md="6">
              <InputText
                textFloat="อีเมล"
                placeholder="อีเมล"
                type="email"
                name="email"
                :isValidate="$v.form.contactUsEmail.$error"
                :v="$v.form.contactUsEmail"
                v-model="form.contactUsEmail"
                isRequired
              />
            </b-col>
            <b-col md="6">
              <InputText
                textFloat="แฟกซ์"
                placeholder="แฟกซ์"
                name="contactUsTelephone"
                :isValidate="$v.form.contactUsFax.$error"
                :v="$v.form.contactUsFax"
                v-model="form.contactUsFax"
                type="text"
                isRequired
              />
            </b-col>
            <b-col md="12">
              <InputTextArea
                class="mb-4"
                rows="8"
                textFloat="คำบรรยาย"
                placeholder="คำบรรยาย"
                type="text"
                name="aboutUsText"
                v-model="form.aboutUsText"
                isRequired
                :isValidate="$v.form.aboutUsText.$error"
                :v="$v.form.aboutUsText"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <label class="font-weight-bold main-label mb-2">
                สถานะการแสดงผล Socials
              </label>
            </b-col>
          </b-row>
          <b-row class="mb-3 align-items-center">
            <b-col md="3">
              <b-form-checkbox
                switch
                v-model="form.isFacebook"
                class="radio-active"
              >
                <span class="ml-1 main-label"
                  >Facebook
                  <span class="text-danger" v-if="form.isFacebook"
                    >*</span
                  ></span
                >
              </b-form-checkbox>
            </b-col>
            <b-col md="9">
              <InputText
                placeholder="https://www.example.com"
                type="text"
                name="lng"
                isRequired
                :isValidate="$v.form.linkFacebook.$error"
                :v="$v.form.linkFacebook"
                v-model="form.linkFacebook"
                className="mb-0"
                :disabled="!form.isFacebook"
              />
            </b-col>
          </b-row>
          <b-row class="mb-3 align-items-center">
            <b-col md="3">
              <b-form-checkbox
                switch
                v-model="form.isInstagram"
                class="radio-active"
              >
                <span class="ml-1 main-label"
                  >Instagram
                  <span class="text-danger" v-if="form.isInstagram"
                    >*</span
                  ></span
                >
              </b-form-checkbox>
            </b-col>
            <b-col md="9">
              <InputText
                placeholder="https://www.example.com"
                type="text"
                name="lng"
                isRequired
                :isValidate="$v.form.linkInstagram.$error"
                :v="$v.form.linkInstagram"
                v-model="form.linkInstagram"
                className="mb-0"
                :disabled="!form.isInstagram"
              />
            </b-col>
          </b-row>
          <b-row class="mb-3 align-items-center">
            <b-col md="3">
              <b-form-checkbox
                switch
                v-model="form.isLine"
                class="radio-active"
              >
                <span class="ml-1 main-label"
                  >Line
                  <span class="text-danger" v-if="form.isLine">*</span></span
                >
              </b-form-checkbox>
            </b-col>
            <b-col md="9">
              <InputText
                placeholder="https://www.example.com"
                type="text"
                name="lng"
                isRequired
                :isValidate="$v.form.linkLine.$error"
                :v="$v.form.linkLine"
                v-model="form.linkLine"
                className="mb-0"
                :disabled="!form.isLine"
              />
            </b-col>
          </b-row>
          <b-row class="mb-3 align-items-center">
            <b-col md="3">
              <b-form-checkbox switch v-model="form.isX" class="radio-active">
                <span class="ml-1 main-label"
                  >X <span class="text-danger" v-if="form.isX">*</span></span
                >
              </b-form-checkbox>
            </b-col>
            <b-col md="9">
              <InputText
                placeholder="https://www.example.com"
                type="text"
                name="lng"
                isRequired
                :isValidate="$v.form.linkX.$error"
                :v="$v.form.linkX"
                v-model="form.linkX"
                className="mb-0"
                :disabled="!form.isX"
              />
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col class="text-sm-right">
              <button
                type="button"
                @click="checkForm()"
                :disabled="isDisable"
                class="btn btn-main btn-details-set ml-md-2 text-uppercase"
              >
                บันทึก
              </button>
            </b-col>
          </b-row>
        </form>
      </div>
    </b-container>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import { required, email, requiredIf, decimal, url } from "vuelidate/lib/validators";
import InputTextArea from "@/components/inputs/InputTextArea";

export default {
  name: "AboutUs",
  components: {
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    InputTextArea,
  },
  data() {
    return {
      isEdit: false,
      isDisable: false,
      isSuccess: false,
      id: this.$route.params.id,
      modalMessage: "",
      center: { lat: 13.719559, lng: 100.585058 },
      form: {
        contactUsAddress: "",
        contactUsTelephone: "",
        contactUsFax: "",
        contactUsEmail: "",
        aboutUsText: "",
        isFacebook: false,
        isInstagram: false,
        isLine: false,
        isX: false,
        lat: "",
        long: "",
        linkFacebook: "",
        linkInstagram: "",
        linkLine: "",
        linkX: "",
      },
    };
  },
  validations: {
    form: {
      contactUsAddress: {
        required,
      },
      contactUsTelephone: { required },
      contactUsFax: { required },
      contactUsEmail: {
        email,
      },
      aboutUsText: { required },
      lat: { required, decimal },
      long: { required, decimal },
      linkFacebook: {
        required: requiredIf(function (item) {
          return item.isFacebook;
        }),
        url
      },
      linkInstagram: {
        required: requiredIf(function (item) {
          return item.isInstagram;
        }),
        url
      },
      linkLine: {
        required: requiredIf(function (item) {
          return item.isLine;
        }),
        url
      },
      linkX: {
        required: requiredIf(function (item) {
          return item.isX;
        }),
        url
      },
    },
  },
  created: async function () {
    this.$isLoading = false;
    await this.getData();
  },
  watch: {
    "form.lat": async function (e) {
      if (e) this.center.lat = parseFloat(e.toString().replace(/,/g, ""));
    },
    "form.long": async function (e) {
      if (e)  this.center.lng = parseFloat(e.toString().replace(/,/g, ""));
    },
  },
  methods: {
    getData: async function () {
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/ContactUs/ConfigFooter`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = {...data.detail};
        this.$v.form.$reset();
      }
      this.$isLoading = true;
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      await this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();
      let data = await this.$callApi(
        "put",
        `${this.$baseUrl}/api/ContactUs/ConfigFooter`,
        null,
        this.$headers,
        this.form
      );

      this.$refs.modalLoading.hide();
      this.modalMessage = data.detail;
      this.isDisable = false;

      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();

        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
.vue-map-container {
  width: 100%;
  height: 450px;
}

.text-error {
  color: #ff0000 !important;
  font-size: 14px;
  margin-top: -14px;
}

@media (max-width: 1200px) {
  .vue-map-container {
    height: 320px;
  }
}

@media (max-width: 767px) {
  .form {
    margin-top: 10px;
  }
}
</style>
